@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800&display=swap");

.App {
  margin: 0;
}

:root {
  --blue: #015ffb;
  --dark-blue: #0243a0;
  --light-blue: #e7f1fe;
  --gray: #999999;
  --light-gray: #f4f4f4;
}

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  background-color: #f4f4f4;
  font-size: 16px;
}

h1 {
  font-size: 1.6em;
}

h2 {
  font-size: 1.4em;
}

h3 {
  font-size: 1.2em;
}

.bold {
  font-weight: 800;
}

.dark-blue {
  color: var(--dark-blue) !important;
}

.container {
  margin: 0 auto;
  padding: 1em;
  max-width: 1200px;
}

.header {
  background-color: var(--blue);
  color: white;
}

.xl {
  font-size: 3em;
  margin-bottom: 0.4em;
  margin-top: 1em;
}

.switch-source {
  border-bottom: 1px solid white;
}

.switch-button {
  display: block;
  background: none;
  border: 0;
  color: var(--dark-blue);
  font-weight: 600;
  font-size: 1.2em;
  padding: 0;
  cursor: pointer;
  text-align: left;
}

.switch-button:hover {
  color: white;
}

.search-box {
  padding: 1em;
  border: 0;
  font-size: 1.2em;
  width: 100%;
  position: relative;
  top: 45px;
  border-radius: 0.5em;
  box-shadow: 0px 0px 15px 0px rgb(0, 0, 0, 0.1);
  color: black;
  transition: all 0.15s ease-in;
}

input[type="search"]:focus {
  box-shadow: 0px 0px 25px 0px rgba(1, 95, 251, 0.6);
  outline: none;
  transition: all 0.15s ease-in;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url("https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg")
    no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  color: var(--blue);
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.5;
  color: var(--blue);
  pointer-events: all;
  cursor: pointer;
}

.list {
  margin-top: 2em;
}

.card {
  background-color: white;
  margin: 1.5em 0;
  border-radius: 1em;
  padding: 2em;
  cursor: pointer;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0);
  transition: all 0.15s ease-in;
}

.card:hover {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.15s ease-out;
  transform: scale(1.01);
}

.card h1 {
  margin: 0;
}

.card h2 {
  font-weight: 500;
  color: black;
  margin: 0.8em 0;
  font-size: 1.2em;
}

.card h3 {
  font-size: 1em;
  font-weight: 500;
  color: var(--gray);
  margin: 0.8em 0 1.6em;
}

.pill {
  background: var(--light-blue);
  color: var(--blue);
  font-weight: 800;
  margin: 1em 1em 1em 0;
  padding: 0.7em 1.6em;
  border-radius: 2em;
  font-size: 0.8em;
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 80%;
  max-height: 95%;
  background-color: white;
  overflow-y: auto;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  border-top: 1px solid var(--blue);
}

.modal-header {
  background: var(--blue);
  color: white;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
}

.modal-header-left,
.modal-header-right {
  padding: 1.5em;
}

.modal-header h1,
h2 {
  margin: 0 0 0.3em;
}

.modal-header h2 {
  font-weight: 400;
  font-size: 1.1em;
}

.modal-header-meta p {
  line-height: 1.6em;
  margin-bottom: 0.3em;
}

.modal-header-meta span {
  font-weight: 600;
}

.modal-header-buttons {
  border: none;
  padding: 0.6em 1.4em;
  border-radius: 100em;
  cursor: pointer;
  font-weight: 600;
  margin: 0.6em 1em 0.3em 0;
  text-decoration: none;
  font-size: 0.9em;
}

.modal-header-buttons-blue {
  color: white;
  background: var(--dark-blue);
}

.modal-header-buttons-white {
  color: var(--blue);
}

.modal-header-buttons:hover {
  opacity: 0.8;
}

.close-button {
  background: white;
  border: none;
  color: var(--blue);
  padding-top: 0.3em;
  border-radius: 50em;
  cursor: pointer;
  width: 50px;
  height: 50px;
  transition: all 0.2s ease;
}

.close-button:hover {
  background-color: var(--dark-blue);
  color: white;
  transform: scale(1.1) rotate(180deg);
  transition: all 0.2s ease;
  padding-top: 0em;
}

.errorWord {
  background-color: var(--light-gray);
  color: var(--gray);
  font-size: 0.8em;
  padding: 0.1em;
}

.modal-body {
  padding: 1em 2em;
}

.modal-body h3 {
  font-size: 1em;
  color: var(--blue);
  margin: 2em 0 1em 0;
}

.modal-body h4 {
  font-size: 1em;
  color: var(--gray);
  font-weight: 600;
  margin: 0.5em 0 0 0;
}

.modal-body p {
  line-height: 1.4em;
  margin: 0.5em 0 1.5em;
}

.modal-body-section {
}

.modal-body-row {
  display: flex;
  justify-content: space-between;
}

.modal-body-col {
  width: 32%;
  padding: 0.5em 0;
  border-bottom: 1px solid var(--light-gray);
}

.modal-footer {
  background: var(--blue);
  padding: 1em 2em;
}

@media only screen and (max-width: 800px) {
  .pill {
    display: block;
  }

  .modal-body-row {
    display: block;
  }

  .modal-body-col {
    width: 100%;
  }

  .modal-header {
    position: relative;
  }

  .modal-content {
    width: 100%;
    max-height: 100%;
  }

  .noMobile {
    display: none;
  }

  .xl {
    font-size: 2.4em;
  }
}
